import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const Somos = () => {
  return (
    <div className='p-5 text-inter' id="beneficios">
      <Row className="align-items-center mb-4 g-0 ">
        <Col md={6} xs={12}>
          <h1 className='fw-bold px-4 mb-3 d-none d-md-block'>¿Por qué somos <span className='color-somos'>tu mejor opción</span>?</h1>
          <h1 className='fw-bold px-3 mb-3 text-center d-block d-md-none'>¿Por qué somos <span className='color-somos'>tu mejor opción</span>?</h1>
        </Col>
        <Col md={6} xs={12}>
          <p className='px-4 mt-2'>Explora nuestra sección de beneficios para descubrir por qué somos la elección preferida en el transporte de vehículos en Centroamérica.</p>
        </Col>
      </Row>
      <Row className='g-0 px-2'>
        <Col md={4} xs={12} className="mb-3 ">
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos1.png" alt="Imagen 1" className='hover-yellow' fluid />
          </div>
        </Col>
        <Col md={4} xs={12} className="mb-3">
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos2.png" alt="Imagen 2"  fluid />
          </div>
        </Col>
        <Col md={4} xs={12} className="mb-3">
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos3.png" alt="Imagen 3"  fluid />
        </div>
        </Col>
      </Row>
      <Row className='px-2 g-0'>
        <Col md={4} xs={12} className="mb-3">
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos4.png" alt="Imagen 4"  fluid />
        </div>
        </Col>
        <Col md={4} xs={12} className="mb-3">
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos5.png" alt="Imagen 5"  fluid />
        </div>
        </Col>
        <Col md={4} xs={12}>
        <div className="image-container position-relative">
            <div className="hover-overlay position-absolute w-100 h-100"></div>
          <Image src="/images/somos6.png" alt="Imagen 6"  fluid />
        </div>
        </Col>
      </Row>
    </div>
  );
};

export default Somos;
