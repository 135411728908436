import React from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';


const Footer = () => {
  const customNavigate = useCustomNavigate();
  return  (
    <div style={{ backgroundImage: 'url("/images/footer.png")', backgroundPosition: 'center', backgroundSize: 'cover', }} className='mt-5 border-top d-none d-md-block'>
      <div className="p-5">
        <Row className=" g-0">
          <Col md={6} className="footer-logo px-5 mt-5">
            {/* Aquí iría tu imagen o logotipo */}
            <img
              src="/carrosacentroamerica.png"
              width="110"
              height="50"
              className="d-inline-block align-top p-2"
              alt="Logo TCP"
            />
          </Col>
          <Col md={6} className='px-5 my-5'>
            <Card className='no-border'>
              <Card.Body>
                <Card.Title className='text-center text-menu fw-bold text-inter'>Menu</Card.Title>
                <Nav className="flex-md-row justify-content-center">
                  <Nav.Item className="mx-2">
                    <Nav.Link onClick={() => customNavigate('#home')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Inicio</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-2">
                    <Nav.Link onClick={() => customNavigate('#beneficios')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Beneficios</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-2">
                    <Nav.Link onClick={() => customNavigate('#steps')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Proceso</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav className="flex-md-row justify-content-center mt-3">
                  <Nav.Item className="mx-2">
                    <Nav.Link onClick={() => customNavigate('#review')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Testimonios</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-2">
                    <Nav.Link onClick={() => customNavigate('#contact')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Contacto</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-2">
                  <Nav.Link onClick={() => customNavigate('#home')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Cotizar</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
