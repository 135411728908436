import React from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';

const FooterMovil = () => {
  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // fondo blanco con transparencia
  };
  const customNavigate = useCustomNavigate();

  return (
    <div style={{ backgroundImage: 'url("/images/footer.png")', backgroundPosition: 'center', backgroundSize: 'cover' }} className='mt-5 border-top d-block d-md-none'>
      <div className="p-5">
        <Row className="g-0">
          <Col md={6} className="footer-logo text-center px-5 mt-5">
            {/* Aquí iría tu imagen o logotipo */}
            <img
              src="/carrosacentroamerica.png"
              width="110"
              height="50"
              className="d-inline-block align-top p-2"
              alt="Logo TCP"
            />
          </Col>
          <Col md={6} className='px-3 my-5'>
            <Card style={cardStyle} className='no-border'>
              <Card.Body>
                <Card.Title className='text-center text-menu fw-bold text-inter mb-5'>Menu</Card.Title>
                <Row>
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#home')} className='text-footer text-inter me-2 mb-3' style={{color: '#89BDC7',}}>Inicio</Nav.Link>
                  </Col>
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#beneficios')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Beneficios</Nav.Link>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#steps')} className='text-footer text-inter me-2 mb-3' style={{color: '#89BDC7',}}>Procesos</Nav.Link>
                  </Col>
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#review')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Testimonios</Nav.Link>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#contact')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Contacto</Nav.Link>
                  </Col>
                  <Col xs={6} className="text-center">
                    <Nav.Link onClick={() => customNavigate('#home')} className='text-footer text-inter' style={{color: '#89BDC7',}}>Cotizar</Nav.Link>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FooterMovil;
