import React from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCustomNavigate } from '../utils/customNavigation';

function Navi() {
    const [expanded, setExpanded] = React.useState(false);
    const customNavigate = useCustomNavigate();
    const navbarClass = expanded ? 'bg-custom-yellow navbar-mobile-expanded' : 'bg-light';
  
    return (
        <>
        <Navbar
          expand="md"
          sticky="top"
          className={navbarClass}
          expanded={expanded}
          onToggle={() => setExpanded(expanded => !expanded)}
        >
        <Container className="navbar-container navbar-mobile text-inter" >
          <Navbar.Brand onClick={() => customNavigate('#home')} >
            <img
              src="/carrosacentroamerica.png"
              width="110"
              height="50"
              className="d-inline-block align-top p-2"
              alt="Logo TCP"
            />
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav-links ms-auto">
              <Nav.Link onClick={() => customNavigate('#home')} className='' style={{color: '#89BDC7',}}>Inicio</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#beneficios')} className='' style={{color: '#89BDC7',}}>Beneficios</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#steps')} className='' style={{color: '#89BDC7',}}>Procesos</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#review')}className=''style={{color: '#89BDC7',}}>Testimonios</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#contact')} className=' ' style={{color: '#89BDC7',}}>Contacto</Nav.Link>
              <Button variant="outline-light" className='nav-button ms-5' onClick={() => customNavigate('#home')} >
                Cotizar
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navi;