import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Review = () => {

   

  return (
    <Container id='review' className=''>
      <h1 className="text-center my-5 text-steps ">Experiencias de nuestros clientes</h1>
      <Row className="justify-content-center">
        <Col md={4} xs={12} className="mb-4">
        <div className="card-hover position-relative">
          <Card className='no-border' style={{ backgroundColor: '#E7F1F4', minHeight: '300px' }}>
            <Card.Body className=' p-4'>
              <Card.Title className='text-center p-4 text-steps '>Juan Ramírez</Card.Title>
              <Card.Text >
              <p  className='px-3 color-review'>"Estoy muy agradecida con la empresa por su excelente servicio. Desde el primer contacto hasta la entrega, demostraron profesionalismo y atención al cliente excepcionales. Recomiendo encarecidamente sus servicios."</p>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
        </Col>
        <Col md={4} xs={12} className="mb-4">
        <div className="card-hover position-relative">
          <Card className='no-border' style={{ backgroundColor: '#E7F1F4', minHeight: '300px' }}>
            <Card.Body className=' p-4'>
              <Card.Title className='text-center p-4 text-steps ' >Carla Martínez</Card.Title>
              <Card.Text>
              <p  className='px-3 color-review'>"Mi experiencia  fue excepcional. Su equipo fue eficiente y atento, asegurándose de que todo el proceso fuera sin problemas. Recomiendo sus servicios a cualquiera que busque transporte de vehículos confiable."</p>
              </Card.Text>
            </Card.Body>
          </Card>
       </div>
        </Col>
        <Col md={4} xs={12} className="mb-4">
        <div className="card-hover position-relative">
          <Card className='no-border' style={{ backgroundColor: '#E7F1F4', minHeight: '300px' }}>
            <Card.Body className=' p-4'>
              <Card.Title className='text-center p-4 text-steps '>Julian Londoño</Card.Title>
              <Card.Text>
              <p className='px-3 color-review'> "Mi experiencia de envío fue excepcional. Su equipo fue eficiente y atento, asegurándose de que todo el proceso fuera sin problemas. Los recomendaré a amigos y familiares este gran servicio,super confiables una calificacion de 5/5."</p>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Review;
