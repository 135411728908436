import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Steps = () => {
  return (
    <>
      <div className='mx-auto px-5'>
        <video src="/videos/video_home_es.mp4" muted loop  autoPlay preload="auto" className='w-100'></video>
      </div>
      <Container id='steps'>
        <h1 className="text-center mt-2 mb-4 text-steps">Envía tu vehículo sin complicaciones</h1>
        <Row className="justify-content-center">
          {/* Repite esto para cada Card */}
          <Col md={4} xs={12} className="mb-4">
            <div className="card-hover position-relative">
              <Card className='no-border'>
                <Card.Body >
                  <Card.Title className='text-center  text-step fw-bold'><h2>Paso 1</h2></Card.Title>
                  <Card.Img variant="top" src="/images/steps1.png" alt="Step 1" />
                  <Card.Text className='color-steps text-inter px-2'>
                    Completa el formulario de contacto ubicado en la parte superior de esta página o llámanos al siguiente número: 1-870-445-6742.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col md={4} xs={12} className="mb-4">
          <div className="card-hover position-relative">
            <Card className='no-border'>
              <Card.Body>
                <Card.Title className='text-center  text-step fw-bold'><h2>Paso 2</h2></Card.Title>
              <Card.Img variant="top" src="/images/steps2.png" alt="Step 2" />
                <Card.Text className='color-steps text-inter px-2'>
                Recibe las cotizaciones de los diferentes transportistas interesados en brindarte el servicio de transporte.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>
          <Col md={4} xs={12}>
          <div className="card-hover position-relative">
            <Card className='no-border'>
              <Card.Body >
                <Card.Title className='text-center text-step fw-bold'><h2>Paso 3</h2></Card.Title>
              <Card.Img variant="top" src="/images/steps3.png" alt="Step 3" />
                <Card.Text className='color-steps text-inter px-2'>
                Evalúa las cotizaciones y elige la más conveniente en términos de precio y servicio. Luego, contrata directamente con la empresa seleccionada.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Steps;
