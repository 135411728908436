import React, {useEffect} from 'react';
import { Button } from 'react-bootstrap';

export default function Gracias() {

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-11239929039/Kqh1CIaGxJsZEM_pzu8p'});
    }
  }, []);
    return(
        <section
        style={{
          backgroundImage: `url(/images/bg.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          paddingTop: '20rem',
          paddingBottom: '20rem',
          position: 'relative', // Make the section a relative container
          overflow: 'hidden', // Prevents any absolutely positioned child from overflowing
        }}
      >
    {/* Overlay */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)', // Dark transparent overlay
        zIndex: 1, // Sits above the background image
      }}
    />

    <div className='container'>
      <div className='row mb-5 text-menu'     style={{
          position: 'relative', // This ensures content sits above the overlay
          zIndex: 2, // Higher zIndex than the overlay
          color: 'white'
        }}>
        <div className='col-12 col-lg-7 mx-auto text-center text-menu section-content'>
          <h1 className='text-menu'>
            ¡Gracias por contactarnos!
          </h1>
          <h1 className='my-5 fs-3 fw-bold text-menu'>
            SOLICITUD RECIBIDA
          </h1>

          <Button variant="outline-light" size='' className='nav-button mt-5'>Volver a cotizar</Button>
        </div>
      </div>
    </div>
  </section>

    )
}