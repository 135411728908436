import React from 'react';
import { Row, Col } from 'react-bootstrap';
import QuoteForm from './FormQuote'

const Home = () => {
  // Estilo para la versión de escritorio
  const backgroundStyle = {
    backgroundImage: 'url("/images/Frame49.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
    borderRadius: '15px',
    overflow: 'hidden'
  };

  // Estilo para la versión móvil
  const backgroundMovil = {
    backgroundImage: 'url("/images/home.png")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '60vh', // Asegúrate de ajustar la altura según sea necesario
    margin: 0,
    padding: 0,
    borderRadius: '15px',
    overflow: 'hidden'
  };

  return (
    <>
      {/* Div para versión de escritorio: visible en md y tamaños superiores */}
      <div id="home" style={backgroundStyle} className='home d-none d-md-block mb-5'>
        <Row className='g-0 text-inter mb-4'>
          <Col md={6} className="d-flex align-items-center justify-content-center px-5">
            <div className='mt-5'>
              {/* Contenido adicional si es necesario */}
            </div>
          </Col>            
          <Col md={6} className="mt-5 p-quote">
            <QuoteForm></QuoteForm>
          </Col>
        </Row>
      </div>

      {/* Div para versión móvil: visible en xs y sm solamente */}
      <div id="home-mobile" style={backgroundMovil} className='home d-md-none mb-4'>
        <Row className='g-0 text-inter '>
          <Col xs={12} className="d-flex align-items-center justify-content-center  ">
            
          </Col>
        </Row>
      </div>
      <div className='mt-5 d-md-none'>
        <QuoteForm></QuoteForm>
      </div>
    </>
  );
};

export default Home;
