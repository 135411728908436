import React from 'react';
import { Image, Row, Col, } from 'react-bootstrap';

const Flag = () => {
  return (
    <div className="text-center my-5 text-inter" id='flags'>
      <div className="d-flex justify-content-center mt-5 d-none d-md-block mt-4">
        <Image src="/images/flag1.png" alt="Description of image 1"  fluid className="custom-img-size "  />
        <Image src="/images/flag2.png" alt="Description of image 2"  fluid className="custom-img-size "  />
        <Image src="/images/flag3.png" alt="Description of image 3"  fluid className="custom-img-size"  />
        <Image src="/images/flag4.png" alt="Description of image 4"  fluid className="custom-img-size"  />
        <Image src="/images/flag5.png" alt="Description of image 5"  fluid className="custom-img-size"  />
        </div>
        <Row className='g-0 mt-3 d-none d-md-block'>
      <h5 className='px-5 '>Envío de autos al interior de USA y a cinco países de Centro América</h5>
      </Row>

      <Row className="justify-content-center mb-3 g-0  d-md-none px-5 mt-5">
    <Col xs={4} className="px-0"><Image src="/images/flag1.png" alt="Flag 1" fluid className="custom-img-size px-2"/></Col>
    <Col xs={4} className="px-0"><Image src="/images/flag2.png" alt="Flag 2" fluid className="custom-img-size px-2"/></Col>
    <Col xs={4} className="px-0"><Image src="/images/flag3.png" alt="Flag 3" fluid className="custom-img-size px-2"/></Col>
  </Row>
  <Row className="justify-content-center mb-3 g-0 d-md-none px-5 mb-3 ">
    <Col xs={6} className="px-0"><Image src="/images/flag4.png" alt="Flag 4" fluid className="custom-img-size mt-3 px-2"/></Col>
    <Col xs={6} className="px-0"><Image src="/images/flag5.png" alt="Flag 5" fluid className="custom-img-size mt-3 px-2"/></Col>
  </Row>
  <h1 className="px-4 d-block d-md-none mt-5">Envío de autos al interior de USA y a cinco países de Centro América</h1>
    </div>
  );
};

export default Flag;
