import React from 'react';
import {  Row, Col } from 'react-bootstrap';
import QuoteForm from './FormQuote';

const Contact = () => {
  return (
    <div id='contact'>
      <Row className="g-0">
        {/* Columna de la izquierda con título, texto e imagen */}
        <Col md={5} className="mb-4">
          <h2 className='px-5 text-steps my-3'>Contáctanos</h2>
          <p className='px-5 text-contact'> Estamos aquí para ayudarte. Ponte en contacto con nosotros para discutir tus necesidades de transporte y recibir asistencia personalizada.</p>
          <img src="/images/contact.png" alt="Ilustración de contacto" className="img-fluid px-5 mt-5" />
        </Col>

        {/* Columna de la derecha con la card del formulario y la información adicional */}
        <Col md={7} className="mb-4">
          <div className='px-0 px-md-5'>
            <QuoteForm></QuoteForm>
          </div>
          <div className="info-rect p-3 mx-5 d-none d-md-block rounded" style={{ backgroundColor: '#CFE4E8', marginTop: '20px' }}>
    <h4 className='text-step fw-bold px-2'>Comunicate con nosotros</h4>
    <div className="d-flex align-items-center px-2">
        <a href="tel:1-870-445-6742" className="phone-number text-like me-2">1-870-445-6742</a>
        <a href="https://www.instagram.com/carrosacentroamerica/" target="_blank" rel="noreferrer">
            <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
        </a>
    </div>
</div>
<div className="info-rect px-5 p-3 mx-5 d-block d-md-none text-center rounded" style={{ backgroundColor: '#CFE4E8',  marginTop: '20px' }}>
    <h4 className='text-step fw-bold px-2'>Comunicate con nosotros</h4>
    <div className="d-flex justify-content-center align-items-center px-2">
        <a href="tel:1-870-445-6742" className="phone-number text-like me-2">1-870-445-6742</a>
        <a href="https://www.instagram.com/carrosacentroamerica/" target="_blank" rel="noreferrer">
            <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
        </a>
    </div>
</div>

        </Col>
      </Row>
    </div>
  );
};

export default Contact;
