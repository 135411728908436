import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navi from './components/Navi';
import Home from './components/Home';
import Flag from './components/Flags';
import Somos from './components/Somos';
import Steps from './components/Steps';
import Review from './components/Review';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FooterMovil from './components/FooterMovil';
import Gracias from './components/Gracias';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_16_p94bzx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <Router>
      <div className="App">
          <Navi/>
          <Routes>
            <Route path="/" element={
            <>
              <Home/>
              <Flag/>
              <Somos/>
              <Steps/>
              <Review/>
              <Contact/>
              </>} />
              <Route path='/gracias'element={<Gracias/>}/>
          </Routes>

          <Footer/>
          <FooterMovil/>


      </div>
    </Router>
  );
}

export default App;
